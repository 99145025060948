<template>
    <div class="page-container">
        <div class="banner-list">
            <!-- <div class="banner-item">
                <div class="banner-left">
                    <div class="aside-title">重磅来袭</div>
                    <div class="main-title">NFTNET即将上线发售基于此著作的“百家姓”系列数字藏品！</div>
                    <div class="btn-buy">
                        <span>前往购买</span>
                        <img src="../../assets/main/btn-buy-arrow.png" alt="" class="buy-arrow">
                    </div>
                </div>
                <div class="banner-right">
                    <img src="../../assets/main/banner-pic.png" alt="">
                </div>
            </div> -->
            <a href="http://front.nftnet.world" target="_blank">
                <img src="../../assets/banner-school.png" alt="" style="display:block;width:100%">
            </a>
            
        </div>
        <div class="page-title">
            <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-left">
            <div>市场</div>
            <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-right">
        </div>
        <div class="product-list">
            <div class="product-item" @click="jumpPage('productdetail1')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-yu.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">「Esprimere 表达」欢腾鱼-青：自由之约</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>墨菲链</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥19.9</span>
                </div>
            </div>
            <div class="product-item" @click="jumpPage('productdetail2')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-fo.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">《灯光佛影》</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>王文喜</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥49.9</span>
                </div>
            </div>
            <div class="product-item" @click="jumpPage('productdetail3')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-book1.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">《中国元宇宙经济白皮书》</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>北京信息产业协会元宇宙专委会（筹）</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥200</span>
                </div>
            </div>
            <div class="product-item" @click="jumpPage('productdetail3')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-book2.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">「Esprimere 表达」欢腾鱼-青：自由之约</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>北京信息产业协会元宇宙专委会（筹）</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥200</span>
                </div>
            </div>
        </div>
        <!-- 更多按钮 -->
        <div class="btn-more" @click="jumpPage('market')">
            <span>探索更多商品</span>
            <img src="../../assets/main/btn-more-arrow.png" alt="" class="btn-more-arrow">
        </div>
        <div class="page-title">
            <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-left">
            <div>艺术家</div>
            <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-right">
        </div>
        <div class="artis-list">
            <div class="artis-item">
                <div class="item-top">
                    <div class="logo-title">
                        <img src="../../assets/tmp-head1.png" alt="" class="item-logo">
                        <div class="item-title">北京信息产业协会元宇宙专家委员会（筹）</div>
                    </div>
                    <div class="title-aside">北京信息产业协会元宇宙专家委员会（筹）致力于保证中国元宇宙行业的健康发展、引领中国…</div>
                </div>
                <div class="item-bottom">
                    <img src="../../assets/tmp-article1.png" alt="" class="item-cover">
                    <div class="bottom-txt">《中国元宇宙白皮书（2022）》收藏证书</div>
                </div>
            </div>
            <div class="artis-item">
                <div class="item-top">
                    <div class="logo-title">
                        <img src="../../assets/tmp-head.png" alt="" class="item-logo">
                        <div class="item-title">黄金九六之家</div>
                    </div>
                    <div class="title-aside">国篮96黄金一代主帅宫鲁鸣发起创办基于情怀， 始于专业。展现中国男篮96黄金一代的精神…</div>
                </div>
                <div class="item-bottom">
                    <img src="../../assets/tmp-article.png" alt="" class="item-cover">
                    <div class="bottom-txt">国篮96黄金一代</div>
                </div>
            </div>
            <div class="artis-item">
                <div class="item-top">
                    <div class="logo-title">
                        <img src="../../assets/tmp-head3.png" alt="" class="item-logo">
                        <div class="item-title">墨霏链</div>
                    </div>
                    <div class="title-aside">墨霏代表作《屌丝男士》，《男模的秘密》资深传媒人十余年文娱产业经验，原CCTV6,光线…</div>
                </div>
                <div class="item-bottom">
                    <img src="../../assets/tmp-article2.png" alt="" class="item-cover">
                    <div class="bottom-txt">墨霏与艺术红酒</div>
                </div>
            </div>
            <div class="artis-item">
                <div class="item-top">
                    <div class="logo-title">
                        <img src="../../assets/tmp-head2.png" alt="" class="item-logo">
                        <div class="item-title">王喜文</div>
                    </div>
                    <div class="title-aside">王喜文，数字艺术家，数字经济专家，工学博士，情报学博士后。自幼学习国画，所画风格多样…</div>
                </div>
                <div class="item-bottom">
                    <img src="../../assets/tmp-article3.png" alt="" class="item-cover">
                    <div class="bottom-txt">国风佛系数字绘画</div>
                </div>
            </div>
        </div>
        <!-- 更多按钮 -->
        <div class="btn-more" @click="jumpPage('artist')">
            <span>查看更多艺术家</span>
            <img src="../../assets/main/btn-more-arrow.png" alt="" class="btn-more-arrow">
        </div>
        <!-- 什么是数字藏品 -->
        <div class="what">
            <div class="what-title">
                <img src="../../assets/main/title-arrow-black.png" alt="" class="arrow-left">
                <div>什么是数字藏品?</div>
                <img src="../../assets/main/title-arrow-black.png" alt="" class="arrow-right">
            </div>
            <div class="what-content">数字藏品是使用区块链技术进行唯一标识的经数字化的特定作品、艺术品和商品，包括但不限于数字画作、图片、音乐、视频、3D模型等各种形式。
数字藏品为虚拟数字商品，而非实物，一经售出，不支持退换。每个数字藏品都映射着特定区块链上的唯一序列号，不可篡改、不可分割，也不能互
相替代。每一个数字藏品都代表特定作品、艺术品和商品或其限量发售的单个数字复制品，记录着其不可篡改的链上权利。数字藏品有特定作品、艺
术品和商品的实际价值做支撑，不具备支付功能等任何货币属性。</div>
        </div>
        <!-- nftword区块 -->
        <div class="nftword">
            <div class="sub-title">NFTNET.world</div>
            <div class="main-title">中国优质数字藏品的分发平台</div>
            <div class="aside-title">从企业项目站点、专业数字藏品市场、数字藏品商城、电商平台、文交所到拍卖行，NFTNET覆盖主流合规数字藏品发行和售卖渠道，支持从赠送到
售卖的多种发行方式，使用法币支付，满足监管合规要求。</div>
            <div class="introduce-list">
                <img src="../../assets/main/nftword-1.png" alt="">
                <img src="../../assets/main/nftword-2.png" alt="">
                <img src="../../assets/main/nftword-3.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        jumpPage(name){
            this.$router.push({
                name
            });
        }
    }
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>